import { isDevelopment } from "./config";

const BASE_URI = isDevelopment ? 'http://localhost:5238' : 'https://leaddaddy-h0byhng4bgddh3cm.westus-01.azurewebsites.net';

const api = {
    submitLeadsRequest: async (leadsRequestData) => {
        try {
            const response = await fetch(BASE_URI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(leadsRequestData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.text();
            const newRequestId = data.split(': ')[1];
            return newRequestId;
        } catch (error) {
            // Error handling remains empty as in the original
        }
    },

    tryGetLeadsResult: async (requestId) => {
        try {
            const response = await fetch(`${BASE_URI}/${requestId}`);
            if (response.status === 200) {
                const data = await response.json();
                return {
                    ...data,
                    hasData: true
                }
            } else if (response.status !== 202) {
                // If status is not 202 (Accepted), stop polling
                throw new Error('Unexpected response status');
            } else {
                return { hasData: false }
            }

            // If status is 202, continue polling
        } catch (error) {
            return { hasData: false, error: true }
        }
    },

    verifyPayment: async (requestId, sessionId) => {
        try {
            const response = await fetch(`${BASE_URI}/verify-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ requestId, sessionId }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Payment verification failed');
            }

            const data = await response.json();
            return {
                success: true,
                fullLeadsUrl: data.fullLeadsUrl
            };
        } catch (error) {
            return {
                success: false,
                message: error.message
            };
        }
    }
}

export default api;