import React, { useState, useEffect } from 'react';
import api from './utils/api';
import { Layout, Typography, Form, Input, Button, Modal, message, Result, Card, Space, Statistic } from 'antd';
import { RocketOutlined, DollarOutlined, LoadingOutlined, SafetyOutlined, ThunderboltOutlined, TeamOutlined, DownloadOutlined } from '@ant-design/icons';
import { isDevelopment } from './utils/config';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;

let didVerifyPayment = false;

const LeadDaddy = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [trialLeadsCount, setTrialLeadsCount] = useState();
  const [fullLeadsCount, setFullLeadsCount] = useState();
  const [fullLeadsUrl, setFullLeadsUrl] = useState(null);

  const theme = {
    colors: {
      primary: '#1890ff',
      secondary: '#52c41a',
      accent: '#faad14',
      background: '#f0f2f5',
      text: '#262626',
    },
    fontSizes: {
      small: '14px',
      medium: '16px',
      large: '20px',
      xlarge: '24px',
    },
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      var requestId = await api.submitLeadsRequest(values);
      setRequestId(requestId);
    } catch (error) {
      message.error('Error submitting request. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId && !didVerifyPayment) {
      didVerifyPayment = true;
      verifyPayment(sessionId);
    }
  }, []);

  useEffect(() => {

    const pollForResults = async (intervalId) => {
      if (!requestId) return;

      var pollResult = await api.tryGetLeadsResult(requestId);

      if (pollResult.hasData) {
        setDownloadUrl(pollResult.url);
        setTrialLeadsCount(pollResult.originalRowCount);
        setFullLeadsCount(pollResult.copyRowCount);
        setLoading(false);
        clearInterval(intervalId);
      }

      if (pollResult.error) {
        message.error('Error while fetching results. Please try again.');
      }
    };

    let intervalId;
    if (requestId) {
      intervalId = setInterval(() => pollForResults(intervalId), 1000);
    }
    return () => clearInterval(intervalId);
  }, [requestId]);

  const handlePayment = () => {
    // Store the requestId in localStorage before redirecting to Stripe payment link
    localStorage.setItem('pendingRequestId', requestId);

    // Redirect to Stripe payment link
    window.location.href = isDevelopment ? 'https://buy.stripe.com/test_3cs6op16Heot4OQ28c' : 'https://buy.stripe.com/bIY4jQ5R6ak273WcMR';
  };

  const verifyPayment = async (sessionId) => {
    try {
      const requestId = localStorage.getItem('pendingRequestId');
      if (!requestId) {
        throw new Error('No pending request found');
      }

      const response = await api.verifyPayment(requestId, sessionId);

      if (response.success) {
        message.success('Payment successful! Full list is ready for download.');
        setFullLeadsUrl(response.fullLeadsUrl);
        setDownloadUrl(response.fullLeadsUrl);
        localStorage.removeItem('pendingRequestId');
      } else {
        throw new Error(response.message || 'Payment verification failed');
      }
    } catch (error) {
      message.error(`Error verifying payment: ${error.message}. Please contact support.`);
    }
  };

  const handleDownloadFullList = () => {
    if (fullLeadsUrl) {
      window.open(fullLeadsUrl, "_blank");
      window.location = window.location.href.split("?")[0];
    }
  };

  const features = [
    { icon: <SafetyOutlined />, title: 'Secure', description: 'Your data is always protected' },
    { icon: <ThunderboltOutlined />, title: 'Fast', description: 'Get leads in minutes, not days' },
    { icon: <TeamOutlined />, title: 'Quality', description: 'Targeted, verified B2B leads' },
  ];

  return (
    <Layout style={{ minHeight: '100vh', background: theme.colors.background }}>
      <Header style={{ background: theme.colors.primary, position: 'fixed', zIndex: 1, width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: 1200, margin: '0 auto' }}>
          <Title level={3} style={{ color: 'white', margin: 0 }}>
            <RocketOutlined /> LeadDaddy
          </Title>
          <Space>
            <a href="mailto: support@leaddaddy.site">
              <Button type="link" style={{ color: 'white' }}>Contact</Button>
            </a>
          </Space>
        </div>
      </Header>
      <Content style={{ padding: '80px 2rem 2rem', maxWidth: 1200, margin: '0 auto' }}>
        <Card style={{ marginBottom: '2rem', textAlign: 'center', background: 'white' }}>
          <Title style={{ color: theme.colors.text }}>
            B2B Lead Generation Made Simple
          </Title>
          <Paragraph style={{ fontSize: theme.fontSizes.medium, marginBottom: '2rem' }}>
            LeadDaddy is your go-to solution for hassle-free, high-quality B2B lead generation. Say goodbye to monthly fees and complicated tools!
          </Paragraph>
          <Space size={[16, 16]} wrap style={{ justifyContent: "center" }}>
            {features.map((feature, index) => (
              <Card key={index} style={{ width: 300, textAlign: 'center' }}>
                {React.cloneElement(feature.icon, { style: { fontSize: '32px', color: theme.colors.primary } })}
                <Title level={4}>{feature.title}</Title>
                <Text>{feature.description}</Text>
              </Card>
            ))}
          </Space>
        </Card>

        <Card title="Generate Your Leads" style={{ marginBottom: '2rem' }}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="companyDescription" label="What does your company do?" rules={[{ required: true }]}>
              <Input.TextArea rows={4} placeholder="e.g., We are an AI-powered contract management platform that automates the creation, tracking, and renewal of contracts..." />
            </Form.Item>
            <Form.Item name="location" label="Target City (North America)" rules={[{ required: true }]}>
              <Input placeholder="e.g., New York, Toronto, Los Angeles" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" icon={<RocketOutlined />} loading={loading}>
                Generate Leads
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Card title="Why Choose LeadDaddy?">
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Paragraph>
              <Text strong>✓ No monthly fees</Text> - Pay only for the leads you need
            </Paragraph>
            <Paragraph>
              <Text strong>✓ High-quality data</Text> - Our system ensures accurate and up-to-date information
            </Paragraph>
            <Paragraph>
              <Text strong>✓ Instant results</Text> - Get your leads in minutes, not days
            </Paragraph>
            <Paragraph>
              <Text strong>✓ Targeted approach</Text> - Customized leads based on your specific needs
            </Paragraph>
          </Space>
        </Card>
      </Content>
      <Footer style={{ textAlign: 'center', background: theme.colors.primary, color: 'white' }}>
        <Space direction="vertical">
          <Text style={{ color: 'white' }}>Need help? Contact us: <a href="mailto:support@leaddaddy.site" style={{ color: 'white', textDecoration: 'underline' }}>support@leaddaddy.site</a></Text>
          <Text style={{ color: 'white' }}>LeadDaddy ©2024 
            {/* | <a href="#" style={{ color: 'white', textDecoration: 'underline' }}>Terms of Service</a> | <a href="#" style={{ color: 'white', textDecoration: 'underline' }}>Privacy Policy</a> */}
          </Text>
        </Space>
      </Footer>

      <Modal
        visible={loading}
        closable={false}
        footer={null}
        centered
      >
        <Result
          icon={<LoadingOutlined style={{ color: theme.colors.primary, fontSize: '48px' }} />}
          title="Generating Your Leads!"
          subTitle="We're working hard to find the best leads for you. This could take up to 30 minutes..."
        />
      </Modal>

      <Modal
        visible={downloadUrl !== null}
        onCancel={() => setDownloadUrl(null)}
        footer={null}
        width={600}
      >
        <Result
          status="success"
          title="Your Leads Are Ready!"
          subTitle={fullLeadsUrl ? "Download your full list of high-quality leads now!" : "Download your trial list or upgrade to the full package for more leads!"}
        />
        <Space direction="vertical" size="large" style={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>
          {fullLeadsUrl ? (
            <Button type="primary" size="large" onClick={handleDownloadFullList} icon={<DownloadOutlined />}>
              Download Full List
            </Button>
          ) : (
            <>
              <Button type="default" size="large" onClick={() => window.open(downloadUrl, '_blank')} icon={<DownloadOutlined />}>
                Download Trial List ({trialLeadsCount} leads)
              </Button>
              <Button type="primary" size="large" onClick={() => setShowPaymentModal(true)} icon={<DollarOutlined />}>
                Download Full List ({fullLeadsCount} leads) - $19.99
              </Button>
            </>
          )}
        </Space>
      </Modal>

      <Modal
        visible={showPaymentModal}
        onOk={handlePayment}
        onCancel={() => setShowPaymentModal(false)}
        okText="Proceed to Payment"
        cancelText="Cancel"
        width={500}
      >
        <Result
          icon={<DollarOutlined style={{ color: theme.colors.accent }} />}
          title="Upgrade to Full List"
          subTitle="Get access to all targeted leads for a one-time fee of $19.99"
        />
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Statistic title="Number of Leads" value={fullLeadsCount} prefix={<TeamOutlined />} />
          <Statistic title="Price per Lead" value={(19.99 / fullLeadsCount).toFixed(2)} prefix="$" />
          <Text strong>Secure payment powered by Stripe</Text>
        </Space>
      </Modal>
    </Layout>
  );
};

export default LeadDaddy;